import type { GuideRecommendationDto } from "@/api/openapi";
import { PublishedDocumentTitleLinkITBestPractice } from "@/components/molecules/PublishedDocumentTitle/PublishedDocumentTitleLinkITBestPractice";
import { PublishedDocumentTitleLinkSoftware } from "@/components/molecules/PublishedDocumentTitle/PublishedDocumentTitleLinkSoftware";

type Props = {
  recommendedGuides: GuideRecommendationDto[];
  onClick?: (urlSlug: string) => void;
};

export function RecommendedGuides(props: Props) {
  return (
    <ul className="m-0 flex list-none flex-col gap-6 p-0">
      {props.recommendedGuides.map(({ guide }) => (
        <li key={guide.id} className="self-start">
          {guide.docType === "IT_BEST_PRACTICE" ? (
            <PublishedDocumentTitleLinkITBestPractice
              urlSlug={guide.urlTitle}
              topicTitle={guide.topic?.title || ""}
              onClick={() => props.onClick?.(guide.urlTitle)}
              className="font-medium"
            />
          ) : (
            <PublishedDocumentTitleLinkSoftware
              urlSlug={guide.urlTitle}
              softwareIconSrc={guide.software?.icon || ""}
              softwareName={guide.software?.name || ""}
              onClick={() => props.onClick?.(guide.urlTitle)}
              className="font-medium"
            />
          )}
        </li>
      ))}
    </ul>
  );
}
