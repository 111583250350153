import cookie from "js-cookie";
import { useLocation } from "react-router";

import type { QuizCalloutClickTakeQuizProperties } from "@/ampli";
import { useAuth } from "@/hooks/use-auth";
import { useITRecommendation } from "@/hooks/use-it-recommendation";
import { paths } from "~/paths";
import { PostRecommendationWidget } from "./PostRecommendationWidget";
import { PreRecommendationWidget } from "./PreRecommendationWidget";
import { SUBMISSION_ID_COOKIE_NAME } from "./utils";

export function ITRecommendation() {
  const { user } = useAuth();
  const submissionId = cookie.get(SUBMISSION_ID_COOKIE_NAME) || "";

  const location = useLocation();

  let trackingLocation: QuizCalloutClickTakeQuizProperties["callout_location"];

  switch (location.pathname) {
    case paths.profile.root:
      trackingLocation = "Client Profile";
      break;
    case paths.documentation.root:
      trackingLocation = "Guides";
      break;
    case paths.sessions.root:
    case paths.sessions.completed:
      trackingLocation = "Sessions";
      break;
    default:
      trackingLocation = "Experts";
  }

  const { data } = useITRecommendation({
    submissionId: user ? "" : submissionId,
    identifier: user?.urlId || user?.id || "",
  });

  // Experts will not see the widget
  if (user?.expertProfile) {
    return null;
  }

  if (!data) {
    return <PreRecommendationWidget trackingLocation={trackingLocation} />;
  }

  return (
    <PostRecommendationWidget
      trackingLocation={trackingLocation}
      recommendation={data}
    />
  );
}
