import { useAuth } from "@/hooks/use-auth";
import { useFeatureFlag } from "~/hooks/use-feature-flag";

export function useIsClientITRecommendationEnabled() {
  const { user } = useAuth();

  const isEnabled = useFeatureFlag("guidestack-client-it-recommendations");
  const isClient = !!user?.clientProfile;
  const isUserTypeKnown = Boolean(user?.expertProfile || user?.clientProfile);

  // Experts will not see the feature
  if (!isUserTypeKnown) {
    return isEnabled;
  }

  return isEnabled && isClient;
}
