import { faClock } from "@awesome.me/kit-af809b8b43/icons/classic/regular";
import {
  Badge,
  Button,
  Group,
  Loader,
  Modal,
  Paper,
  Stack,
  Text,
} from "@flpstudio/design-system";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import * as TypeForm from "@typeform/embed"; // Package is CommonJS
import "@typeform/embed/build/css/popup.css";
import { useDisclosure } from "@mantine/hooks";
import { useEffect } from "react";
import { useNavigate } from "react-router";

import { type QuizCalloutClickTakeQuizProperties, ampli } from "@/ampli";
import WomanGraphAnalysis from "@/assets/images/woman-graph-analysis.svg";
import { useAuth } from "@/hooks/use-auth";
import { useITRecommendationTypeFormQuizMutation } from "@/hooks/use-it-recommendation-mutation";
import { paths } from "~/paths";
import { saveSubmissionId } from "./utils";

type Props = {
  trackingLocation: QuizCalloutClickTakeQuizProperties["callout_location"];
};

let typeForm: TypeForm.Popup;

export function PreRecommendationWidget(props: Props) {
  const { user } = useAuth();

  const itRecommendationTypeFormQuizMutation =
    useITRecommendationTypeFormQuizMutation();
  const [isQuizSubmittedMessageOpen, quizSubmittedMessageHandlers] =
    useDisclosure();
  const navigate = useNavigate();

  useEffect(() => () => typeForm?.unmount(), []);

  function takeQuiz() {
    const submissionId = crypto.randomUUID();

    typeForm = TypeForm.createPopup(import.meta.env.VITE_TYPEFORM_ID, {
      autoClose: true,
      hidden: {
        submission_id: submissionId,
        name: user ? `${user?.firstName} ${user?.lastName}` : "",
        email: user?.email || "",
        phone_number: "",
        utm_source: "",
      },
      async onSubmit() {
        if (!user) {
          saveSubmissionId(submissionId);
        } else {
          itRecommendationTypeFormQuizMutation.mutate({ submissionId });
        }

        const MODAL_DELAY = 500;
        const NAVIGATION_DELAY = 2500;
        /**
         * Serves two purposes:
         * 1. Let the TypeForm animation finish before showing the modal
         * 2. Provide ample time for the backend to process the submission
         */
        setTimeout(() => {
          quizSubmittedMessageHandlers.open();
          setTimeout(() => navigate(paths.itRecommendation), NAVIGATION_DELAY);
        }, MODAL_DELAY);
      },
    });

    typeForm.open();

    ampli.quizCalloutClickTakeQuiz({
      user_status: user ? "Logged In" : "Logged Out",
      callout_location: props.trackingLocation,
    });
  }

  return (
    <Paper className="-mx-6 flex gap-4 rounded-none bg-gradient-to-r from-[#E2DFFF] to-[#F5EBF2] lg:mx-0 lg:rounded-lg">
      <Stack>
        <Badge color="violet.3" className="py-3 text-base/normal text-inherit">
          <FontAwesomeIcon icon={faClock} className="mr-1" />
          5-10 min
        </Badge>
        <Text className="font-semibold text-2xl">
          Quiz: Is your IT working for you?
        </Text>
        <Text>
          Take our short quiz to identify your company’s IT needs and risks.
          Based on your results, we’ll provide action items and a recommended
          GuideStack expert for a free consultation.
        </Text>
        <Button onClick={takeQuiz} className="lg:self-start">
          Check your IT health
        </Button>
      </Stack>
      <div className="ml-auto hidden items-center lg:flex">
        <img
          alt="woman analysing a graph"
          src={WomanGraphAnalysis}
          loading="lazy"
          width={134}
          height={134}
        />
      </div>
      <Modal
        opened={isQuizSubmittedMessageOpen}
        onClose={() => {}}
        title="Quiz submitted"
        size="md"
      >
        <Group>
          <Loader />
          <span>Compiling our recommendations for you...</span>
        </Group>
      </Modal>
    </Paper>
  );
}
