import {
  faClose,
  faSearch,
} from "@awesome.me/kit-af809b8b43/icons/classic/regular";
import { TextInput, UnstyledButton } from "@flpstudio/design-system";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { clsx } from "clsx/lite";
import type { MouseEvent } from "react";
import { Form, useLocation, useSearchParams } from "react-router";

import { ampli } from "@/ampli";
import { paths } from "~/paths";

type Props = {
  className?: string;
};

export function SearchBox(props: Props) {
  const { pathname } = useLocation();
  const [searchParams] = useSearchParams();

  const searchQuery =
    pathname === paths.search ? searchParams.get("q")?.trim() || "" : "";

  function focusToInput(event: MouseEvent<HTMLButtonElement>) {
    const input: HTMLInputElement = event.currentTarget.closest("form")?.q;

    input.value = "";
    input.focus();
  }

  return (
    <Form
      // TODO: Wrapping <form> with <search> is a better alternative that role="search", but React does not support it yet
      // biome-ignore lint/a11y/useSemanticElements: role="search" is valid
      role="search"
      method="GET"
      action={paths.search}
      onSubmit={(event) => {
        const q = event.currentTarget.q.value.trim();

        // Prevent form submission if search query is empty
        if (q === "") {
          event.preventDefault();
          return;
        }

        ampli.enterSearchTerm({
          search_term: q,
        });
      }}
      className={clsx("flex items-center", props.className)}
    >
      <TextInput
        type="search"
        aria-label="Search for guides, experts, comments, and more"
        placeholder="Search GuideStack"
        id="searchbox-input"
        name="q"
        key={searchQuery} // force re-render when searchQuery changes
        defaultValue={searchQuery}
        leftSection={
          <FontAwesomeIcon
            icon={faSearch}
            className="text-[--mantine-color-gray-6]"
          />
        }
        leftSectionPointerEvents="none"
        rightSection={
          <UnstyledButton
            aria-label="Clear search query"
            onClick={focusToInput}
          >
            <FontAwesomeIcon
              icon={faClose}
              className="pointer-events-none text-[--mantine-color-gray-6]"
            />
          </UnstyledButton>
        }
        classNames={{
          input: "px-10 rounded-full peer",
          section: "peer-placeholder-shown:hidden",
          root: "peer sr-only has-[input:focus]:not-sr-only has-[input:focus]:w-full has-[input:not(:placeholder-shown)]:not-sr-only has-[input:not(:placeholder-shown)]:w-full lg:not-sr-only lg:w-full",
        }}
      />
      <UnstyledButton
        aria-label="Open search box"
        className="peer-has-[input:focus]:hidden peer-has-[input:not(:placeholder-shown)]:hidden lg:hidden"
        onClick={focusToInput}
      >
        <FontAwesomeIcon
          icon={faSearch}
          className="pointer-events-none text-[--mantine-color-gray-6]"
        />
      </UnstyledButton>
    </Form>
  );
}
