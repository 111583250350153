import type { Variant } from "@amplitude/experiment-node-server";
import { useRouteLoaderData } from "react-router";

export function useFeatureFlag(flag: string) {
  const rootData = useRouteLoaderData<{
    featureFlags: Record<string, Variant>;
  }>("root");

  if (!rootData) {
    return false;
  }

  const featureFlags = rootData.featureFlags;
  return featureFlags[flag]?.value === "on";
}
