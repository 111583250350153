import { Button, Text, UnstyledButton } from "@flpstudio/design-system";
import clsx from "clsx/lite";

import { useSignUpSearchParams } from "@/hooks/use-page-search-params";
import { Amplitude, PreSignupCalloutEvent } from "@/third-party/amplitude";

type SupportBannerProps = {
  trackingLocation: string;
  className?: string;
};
export function SignUpBanner({
  className,
  trackingLocation,
}: SupportBannerProps) {
  const { openSignUpModal } = useSignUpSearchParams();

  return (
    <UnstyledButton
      onClick={() => {
        openSignUpModal();
        Amplitude.track(PreSignupCalloutEvent.name, {
          action: PreSignupCalloutEvent.properties.clickSignUp.action,
          location: trackingLocation,
        });
      }}
      className={clsx(
        className,
        "flex flex-col gap-4 rounded-lg bg-gradient-to-br from-[#2B0693] to-[#100235] p-6 text-white shadow-sm",
      )}
    >
      <Text className="font-semibold text-2xl">
        Get 1x1 advice from IT experts
      </Text>
      <Text>
        Have a voucher code? Sign up to redeem your free 1-hour session.
      </Text>
      <Button component={Text} className="w-fit">
        Sign up for free
      </Button>
    </UnstyledButton>
  );
}
