import parseHtml, {
  attributesToProps,
  type DOMNode,
  domToReact,
  Element,
} from "html-react-parser";
import DOMPurify from "isomorphic-dompurify";

export function SafeRenderer({ content }: { content: string }) {
  return parseHtml(DOMPurify.sanitize(content), {
    replace: (domNode) => {
      if (
        domNode instanceof Element &&
        domNode.attribs &&
        domNode.name === "a"
      ) {
        // Modify the <a> tag to include target and rel attributes
        const props = attributesToProps(domNode.attribs);
        return (
          <a {...props} target="_blank" rel="noopener noreferrer nofollow">
            {domToReact(domNode.children as DOMNode[])}
          </a>
        );
      }
    },
  });
}
