import { Text } from "@flpstudio/design-system";

export function ComponentError() {
  return (
    <div className="flex flex-col gap-4">
      <Text className="font-semibold">Something went wrong here.</Text>
      <Text className="text-[--mantine-color-gray-5]">
        Sorry, we are having some technical issues, we are working quick to
        resolve the issue. Try to refresh the page, sometimes it helps :)
      </Text>
    </div>
  );
}
