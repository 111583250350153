import {
  Avatar,
  Badge,
  Group,
  Stack,
  Text,
  UnstyledButton,
} from "@flpstudio/design-system";
import { clsx } from "clsx/lite";

import LinkedInLogo from "@/assets/logos/linkedin.png";
import { useExpertFullProfileSearchParams } from "@/hooks/use-page-search-params";
import { countryList } from "@/utils/country-list";

import { TextOverflowWrapper } from "@/components/atoms/TextOverflowWrapper";
import { findStateByAbbreviation } from "@/utils/state-list";
import type { User } from "types";

function UserLocation(props: {
  city: string;
  countryCode: string;
  stateOrProvince: string;
}) {
  const { city, countryCode, stateOrProvince } = props;
  const country = countryList.find(
    (country) => country.code === countryCode,
  )?.name;
  const stateName = findStateByAbbreviation(stateOrProvince, countryCode);
  return (
    <Text span className="text-[--mantine-color-gray-7] text-sm">
      {[city, stateName, country].filter(Boolean).join(", ")}
    </Text>
  );
}

function UserIndustries(props: { industries: string[] }) {
  return (
    <Group component="ul" className="m-0 list-none gap-2 p-0">
      {props.industries.map((industry) => (
        <li key={industry}>
          <Badge variant="light" color="gray">
            {industry}
          </Badge>
        </li>
      ))}
    </Group>
  );
}

type UserProfileProps = {
  user: Partial<User>;
  showStatus?: boolean;
  showSummary?: boolean | "truncate";
  className?: string;
  showFullProfileOnClick?: boolean;
  onExpertView?: () => void;
};

export function UserProfile(props: UserProfileProps) {
  const { setExpertIdentifier } = useExpertFullProfileSearchParams();

  const {
    city,
    countryCode,
    stateOrProvince,
    expertProfile,
    clientProfile,
    industries,
    status,
    summary,
    email,
    linkedinUrl,
  } = props.user;

  const isApproved = status === "APPROVED";
  const linkedin =
    linkedinUrl || expertProfile?.linkedinUrl || clientProfile?.linkedinUrl;
  const userIndustries = industries || expertProfile?.industries || [];
  const userSummary = summary || expertProfile?.summary;
  const { company: clientCompany, jobTitle: clientJobTitle } =
    clientProfile || {};

  const showFullProfile = props.showFullProfileOnClick ?? true;
  const displayFullProfile = () => {
    setExpertIdentifier(props.user.urlId || props.user.id || "");
    props.onExpertView?.();
  };

  return (
    <div
      className={clsx(
        "flex flex-col gap-4 lg:flex-row lg:items-start",
        props.className,
      )}
    >
      {showFullProfile ? (
        <UnstyledButton
          aria-label="Show full profile"
          onClick={displayFullProfile}
        >
          <Avatar
            src={props.user.profileImage}
            alt="Profile"
            className="size-16 shrink-0"
          />
        </UnstyledButton>
      ) : (
        <Avatar
          src={props.user.profileImage}
          alt="Profile"
          className="size-16 shrink-0"
        />
      )}
      <Stack className="gap-2">
        <Group className="gap-1">
          <Text
            span
            className={clsx(
              "font-medium text-xl",
              showFullProfile &&
                "cursor-pointer hover:text-[--mantine-primary-color-filled-hover] hover:underline",
            )}
            {...(showFullProfile && { onClick: displayFullProfile })}
          >
            {[props.user.firstName, props.user.lastName].join(" ").trim() ||
              props.user.name}
          </Text>
          {props.user.status && props.showStatus && (
            <Badge variant="light" color={isApproved ? "green" : "gray"}>
              {isApproved ? "Approved" : "Joined"}
            </Badge>
          )}
          {linkedin && (
            <UnstyledButton
              aria-label="View LinkedIn profile"
              component="a"
              href={linkedin}
              target="_blank"
              rel="noreferrer noopener nofollow"
              className="size-[1.125rem]"
            >
              <img
                src={LinkedInLogo}
                alt="LinkedIn"
                loading="lazy"
                className="size-[1.125rem]"
              />
            </UnstyledButton>
          )}
        </Group>
        {clientCompany?.name && clientJobTitle && (
          <Text className="text-[--mantine-color-gray-7] text-sm">
            {clientJobTitle}, {clientCompany.name}
          </Text>
        )}
        {userIndustries.length > 0 && (
          <UserIndustries industries={userIndustries} />
        )}
        {countryCode && (
          <UserLocation
            city={city || ""}
            stateOrProvince={stateOrProvince ? stateOrProvince : ""}
            countryCode={countryCode}
          />
        )}
        {clientProfile && email && (
          <Text className="text-[--mantine-color-gray-7] text-sm">{email}</Text>
        )}
        {userSummary && props.showSummary && (
          <TextOverflowWrapper
            className={clsx(props.showSummary === "truncate" && "line-clamp-3")}
          >
            {userSummary}
          </TextOverflowWrapper>
        )}
      </Stack>
    </div>
  );
}
