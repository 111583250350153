import { Button } from "@flpstudio/design-system";
import { useDisclosure } from "@mantine/hooks";

import {
  useAvailableAdvisorySessionBooking,
  useAvailableIntroBooking,
  useRemoveAvailableIntroBooking,
} from "@/hooks/use-booking";
import { CalendlyAddBookingDialog } from "../CalendlyAddBookingDialog";

type Props = {
  expertIdentifier: string;
  agenda?: string;
  onClick?: () => void;
};

export function IntroductorySessionBooking(props: Props) {
  const [opened, handlers] = useDisclosure();

  // Introductory session booking is dependent on the availability of an advisory session booking
  const availableAdvisorySessionBooking = useAvailableAdvisorySessionBooking({
    identifier: props.expertIdentifier,
  });

  const availableIntroBooking = useAvailableIntroBooking({
    identifier: availableAdvisorySessionBooking.data
      ? props.expertIdentifier
      : "",
  });

  const removeAvailableIntroBooking = useRemoveAvailableIntroBooking({
    identifier: props.expertIdentifier,
  });

  if (!availableAdvisorySessionBooking.data) {
    return null;
  }

  return (
    <>
      {availableIntroBooking.data && (
        <Button
          variant="outline"
          className="h-[3.25rem]"
          onClick={() => {
            props.onClick?.();
            handlers.open();
          }}
        >
          Book a <br /> free intro
        </Button>
      )}
      <CalendlyAddBookingDialog
        expertIdentifier={props.expertIdentifier}
        calendlySchedulingUrl={
          availableIntroBooking.data?.calendlySchedulingUrl || ""
        }
        agenda={props.agenda}
        bookingType="INTRO_BOOKING"
        opened={opened}
        onConfirm={removeAvailableIntroBooking}
        onClose={handlers.close}
      />
    </>
  );
}
