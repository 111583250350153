import type { ExpertRecommendationDto } from "@/api/openapi";
import { Stack } from "@flpstudio/design-system";
import { TextOverflowWrapper } from "../../atoms/TextOverflowWrapper";
import { Booking, type BookingType } from "../Booking/Booking";
import { UserProfile } from "../UserProfile/UserProfile";

type Props = {
  className?: string;
  recommendedExpert: ExpertRecommendationDto;
  onBookSessionClick?: (bookingType: BookingType) => void;
  onExpertView?: () => void;
};

export function RecommendedExpert(props: Props) {
  const expertIdentifier =
    props.recommendedExpert.expert.urlId || props.recommendedExpert.expert.id;
  const calendlyAgenda =
    Array.from(new Set(props.recommendedExpert.topics))
      ?.map((topic, index) => `${index + 1}. ${topic}`)
      .join("\n") || "";

  return (
    <Stack className={props.className}>
      <UserProfile
        user={props.recommendedExpert.expert}
        onExpertView={props.onExpertView}
      />
      {props.recommendedExpert.expert.summary && (
        <TextOverflowWrapper className="line-clamp-3 lg:ml-20 lg:line-clamp-2">
          {props.recommendedExpert.expert.summary}
        </TextOverflowWrapper>
      )}
      <Booking
        expertIdentifier={expertIdentifier}
        agenda={calendlyAgenda}
        onBookSessionClick={props.onBookSessionClick}
        className="grid lg:ml-20 lg:grid-cols-2"
      />
    </Stack>
  );
}
