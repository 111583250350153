import { Notifications } from "@flpstudio/design-system";
import { QueryClientProvider } from "@tanstack/react-query";

import { AuthProvider } from "@/providers/AuthProvider";
import { Unauthenticated } from "@/screens/unauthenticated/Unauthenticated";
import { queryClient } from "src/AppClient";
import "../tailwind.css";

export default function Layout() {
  return (
    <QueryClientProvider client={queryClient}>
      <AuthProvider>
        <Notifications />
        <Unauthenticated />
      </AuthProvider>
    </QueryClientProvider>
  );
}
