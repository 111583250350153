import {
  Container,
  Divider,
  Paper,
  Stack,
  Text,
} from "@flpstudio/design-system";
import type { MetaFunction } from "react-router";

import type { DocumentAuthorDto } from "@/api/openapi";
import { Skeleton } from "@/components/atoms/Loading/Skeleton";
import { ComponentError } from "@/components/organisms/Error/ComponentError";
import { ITRecommendation } from "@/components/organisms/ITRecommendation/ITRecommendation";
import { SignUpBanner } from "@/components/organisms/SignUpBanner";
import { ClientNextStepCallout } from "@/components/organisms/UserCallouts/ClientNextStepCallout";
import { ExpertCalloutContainer } from "@/components/organisms/UserCallouts/ExpertCalloutContainer";
import { UserProfile } from "@/components/organisms/UserProfile/UserProfile";
import { useAuth } from "@/hooks/use-auth";
import { useExpertsList } from "@/hooks/use-expert";
import {
  Amplitude,
  ExpertDirectoryEvent,
  buildExpertProperties,
} from "@/third-party/amplitude";
import type { Author } from "@/types";
import { useIsClientITRecommendationEnabled } from "~/hooks/use-is-client-it-recommendation-enabled";

export default function Experts() {
  const { role, user } = useAuth();
  const isClientITRecommendationEnabled = useIsClientITRecommendationEnabled();

  const {
    data: expertData,
    isLoading,
    error,
  } = useExpertsList({
    includeNewJoiner: role === "admin",
  });

  let prevStatus = expertData?.[0]?.status;
  return (
    <Container component="main" className="flex flex-col gap-4">
      {isClientITRecommendationEnabled && <ITRecommendation />}
      <div className="flex flex-col-reverse gap-4 lg:flex-row">
        <Stack className="grow basis-2/3">
          <Text>
            View available GuideStack experts and book advisory sessions.
          </Text>
          <Paper className="-mx-6 lg:mx-0">
            {isLoading && <Skeleton repeat={5} />}
            {expertData?.length ? (
              <ul className="m-0 list-none p-0">
                {expertData.flatMap((expert) => {
                  const ret = [];
                  if (prevStatus !== expert.status) {
                    ret.push(
                      <Divider key="divider" component="li" className="-m-6" />,
                    );
                  }
                  prevStatus = expert.status;
                  ret.push(
                    <Expert
                      key={expert.id}
                      user={expert as DocumentAuthorDto}
                    />,
                  );
                  return ret;
                })}
              </ul>
            ) : null}
            {error && <ComponentError />}
          </Paper>
        </Stack>
        {user?.clientProfile && !isClientITRecommendationEnabled && (
          <aside className="hidden basis-1/3 empty:hidden lg:block">
            <ClientNextStepCallout className="-mx-6 lg:mx-0" />
          </aside>
        )}
        {user?.expertProfile && (
          <aside className="hidden basis-1/3 lg:block">
            <ExpertCalloutContainer className="-mx-6 lg:mx-0" />
          </aside>
        )}
        {!user && (
          <aside className="-mx-6 basis-1/3 lg:mx-0">
            <SignUpBanner
              trackingLocation="Experts"
              className="w-full rounded-none lg:rounded-lg"
            />
          </aside>
        )}
      </div>
    </Container>
  );
}

function Expert({ user }: { user: DocumentAuthorDto }) {
  const { role } = useAuth();

  return (
    <li key={user.id} className="my-12 first-of-type:mt-0 last-of-type:mb-0">
      <UserProfile
        user={user}
        showStatus={role === "admin"}
        showSummary="truncate"
        onExpertView={() => handleExpertView(user)}
      />
    </li>
  );
}

export function ErrorBoundary() {
  return (
    <Container component="main">
      <ComponentError />
    </Container>
  );
}

function handleExpertView(user: DocumentAuthorDto) {
  Amplitude.track(ExpertDirectoryEvent.name, {
    ...ExpertDirectoryEvent.properties.clickExpertName,
    ...buildExpertProperties(user as Author),
  });
}

export const meta: MetaFunction = () => {
  const title = "Experts - GuideStack";

  return [
    {
      title,
    },
    {
      property: "og:title",
      content: title,
    },
  ];
};
