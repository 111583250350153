import { Stack, Title, type TitleProps } from "@flpstudio/design-system";

import { LinkPreview } from "@/components/atoms/LinkPreview/LinkPreview";
import { SoftwareGroup } from "@/components/organisms/SoftwareGroup/SoftwareGroup";
import { useSoftware } from "@/hooks/use-software";
import type { SDocumentContentPrompt, Software } from "types";
import docStyles from "../css/Document.module.css";
import richTextStyles from "../css/Richtext.module.css";
import { SafeRenderer } from "./SafeRender";

type SoftwareRecommendationsProps = {
  recommendations: Software[];
  onClick?: (software: string) => void;
};

export function SoftwareRecommendations({
  recommendations,
  onClick,
}: SoftwareRecommendationsProps) {
  // Software stored in the backend has some additional information,
  // such as documents written about them
  const { data: softwareList = [], isLoading } = useSoftware({
    includeDocuments: true,
    softwareIds: recommendations.map(({ id }) => id).join(","),
  });

  if (isLoading) {
    return null;
  }

  const mergedSoftwareList = recommendations.map((software) => {
    const softwareWithMoreInfo = softwareList.find(
      ({ id }) => id === software.id,
    );

    return softwareWithMoreInfo || software;
  });

  return <SoftwareGroup list={mergedSoftwareList} onClick={onClick} />;
}

type BestPracticeDocumentRendererProps = {
  content: SDocumentContentPrompt;
  currentTitleOrder: number;
  onLinkPreviewClick?: (url: string) => void;
  onSoftwareRecommendationClick?: (software: string) => void;
};

export function BestPracticeDocumentRenderer({
  content,
  currentTitleOrder,

  onLinkPreviewClick,
  onSoftwareRecommendationClick,
}: BestPracticeDocumentRendererProps) {
  const nextTitleOrder = currentTitleOrder + 1;

  if (content.value === "") {
    return null;
  }

  return (
    <Stack className={docStyles.document}>
      {content.metadata?.recommendations?.value?.length ? (
        <div className="flex flex-col gap-4">
          <span className="font-semibold">Recommended tools</span>
          {content.metadata?.recommendations.value && (
            <SoftwareRecommendations
              recommendations={content.metadata?.recommendations.value}
              onClick={onSoftwareRecommendationClick}
            />
          )}
        </div>
      ) : null}
      <Title
        order={currentTitleOrder as TitleProps["order"]}
        className="sr-only"
      >
        {content.title}
      </Title>
      {content.value && (
        <div className={richTextStyles.richText}>
          <SafeRenderer content={content.value} />
        </div>
      )}
      {content.metadata?.relevantArticles?.value?.length ? (
        <div className="flex flex-col gap-4">
          <Title order={3}>Relevant articles for further learning</Title>
          <ul className="m-0 flex list-none flex-col gap-4 p-0">
            {content.metadata?.relevantArticles.value.map((article) => (
              <li key={article.url} className="flex gap-2">
                <LinkPreview
                  href={article.url}
                  title={article.title}
                  description={article.description}
                  favicon={article.favicon}
                  onClick={() => onLinkPreviewClick?.(article.url)}
                />
              </li>
            ))}
          </ul>
        </div>
      ) : null}
      {content.subPrompts?.map((subPrompt) => (
        <BestPracticeDocumentRenderer
          key={subPrompt.id}
          content={subPrompt}
          currentTitleOrder={nextTitleOrder as number}
          onLinkPreviewClick={onLinkPreviewClick}
          onSoftwareRecommendationClick={onSoftwareRecommendationClick}
        />
      ))}
    </Stack>
  );
}
