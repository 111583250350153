import {
  faBars,
  faBell,
  faChevronDown,
  faClose,
} from "@awesome.me/kit-af809b8b43/icons/classic/regular";
import {
  Anchor,
  Avatar,
  Button,
  Container,
  Drawer,
  Group,
  Menu,
  Tabs,
} from "@flpstudio/design-system";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useDisclosure } from "@mantine/hooks";
import { clsx } from "clsx/lite";
import { useState } from "react";
import {
  Link,
  type To,
  matchPath,
  useLocation,
  useNavigate,
} from "react-router";

import { useAuth } from "@/hooks/use-auth";
import { useNotificationCount } from "@/hooks/use-notifications";
import { useSignUpSearchParams } from "@/hooks/use-page-search-params";
import { useScreenSize } from "@/hooks/use-screen-size";
import { UnreadSpot } from "@/screens/notifications/NotificationItem";
import { paths } from "~/paths";
import { Logo } from "../../atoms/Logo/Logo";
import { SearchBox } from "../../organisms/SearchBox/SearchBox";
import { UserMenu } from "../UserMenu/UserMenu";

function getNavItems() {
  return [
    {
      label: "Experts",
      to: paths.experts.root,
    },
    {
      label: "Guides",
      to: paths.documentation.root,
    },
    {
      label: "Sessions",
      to: paths.sessions.root,
    },
  ];
}

/**
 * If the pathname is a subpath of a navigation destination, return the base path
 * It is used to highlight the active tab in the navigation bar
 * @param pathname
 */
function getPathNameBase(pathname: string) {
  if (pathname === paths.root) {
    return paths.experts.root;
  }

  return (
    getNavItems()
      .map((destination) => matchPath(`${destination.to}/*`, pathname))
      .find(Boolean)?.pathnameBase || ""
  );
}

type NavigationDrawerProps = {
  onOpen?: () => void;
  onClose?: () => void;
};

function NavigationDrawer(props: NavigationDrawerProps) {
  const [opened, handlers] = useDisclosure();
  const navigate = useNavigate();
  const location = useLocation();

  return (
    <>
      <Button
        variant="outline"
        aria-label="navigation drawer"
        className={clsx(
          "size-6 h-auto border-none p-1",
          !opened && "text-[--mantine-color-gray-7]",
        )}
        onClick={() => {
          handlers.toggle();
          props.onOpen?.();
        }}
      >
        <FontAwesomeIcon icon={opened ? faClose : faBars} />
      </Button>
      <Drawer
        position="top"
        opened={opened}
        onClose={handlers.toggle}
        classNames={{
          content: "h-auto rounded-t-none mt-16 p-0",
          body: "px-6 py-4",
        }}
        onTransitionEnd={() => {
          if (!opened) {
            props.onClose?.();
          }
        }}
      >
        <Tabs
          value={getPathNameBase(location.pathname)}
          orientation="vertical"
          withUnderline={false}
          onChange={(pathname) => {
            navigate(pathname as To);
            handlers.toggle();
          }}
        >
          <Tabs.List className="!gap-2 w-full">
            {getNavItems().map((destination) => (
              <Tabs.Tab
                key={destination.to}
                value={destination.to}
                className="data-[active=true]:!font-normal h-10 px-2 data-[active=true]:bg-[--mantine-primary-color-0] data-[active=true]:text-[--mantine-color-text]"
              >
                {destination.label}
              </Tabs.Tab>
            ))}
          </Tabs.List>
        </Tabs>
      </Drawer>
    </>
  );
}

type NavigationBarProps = {
  className?: string;
};

function NavigationBar(props: NavigationBarProps) {
  const { user, role, setRole } = useAuth();
  const navigate = useNavigate();
  const location = useLocation();
  const { isLg } = useScreenSize();

  const [isOnTop, setIsOnTop] = useState(false);
  const isSignedIn = !!user;

  return (
    // please verify if the rich text editor (TineMCE)'s sticky toolbar works fine
    // if any changes that may affect the height of the navigation bar are made
    <header
      className={clsx(
        "relative bg-white lg:shadow-paper",
        isOnTop ? "z-[999]" : "z-auto", // When the drawer is opened, it should be on top
        props.className,
      )}
    >
      <Container component="nav" className="max-w-full px-6 py-0">
        <Group className="group h-[4.5rem]">
          {/*In small screen only, hide when searchbox is focused or has input*/}
          <Group className="max-lg:group-has-[input:focus]:hidden max-lg:group-has-[input:not(:placeholder-shown)]:hidden">
            {!isLg && (
              <NavigationDrawer
                onOpen={() => setIsOnTop(true)}
                onClose={() => setIsOnTop(false)}
              />
            )}
            <Anchor
              component={Link}
              to={isSignedIn ? paths.root : paths.external.landing}
              className="flex"
            >
              <Logo />
            </Anchor>
            {isLg && (
              <Tabs
                value={getPathNameBase(location.pathname)}
                onChange={(pathname) => navigate(pathname as To)}
              >
                <Tabs.List>
                  {getNavItems().map((destination) => (
                    <Tabs.Tab
                      key={destination.to}
                      value={destination.to}
                      className="-mb-[1.35rem] rounded-none p-0 pb-[1.15rem] font-medium text-xl/normal data-[active=true]:font-bold data-[active=true]:text-[--mantine-color-text]"
                    >
                      {destination.label}
                    </Tabs.Tab>
                  ))}
                </Tabs.List>
              </Tabs>
            )}
          </Group>
          <SearchBox className="ml-auto has-[input:focus]:grow has-[input:not(:placeholder-shown)]:grow" />
          {user ? (
            // In small screen only, hide when searchbox is focused or has input
            <Group className="max-lg:group-has-[input:focus]:hidden max-lg:group-has-[input:not(:placeholder-shown)]:hidden">
              {user?.isAdmin && (
                <Menu>
                  <Menu.Target>
                    <Button
                      variant="transparent"
                      aria-label={`Switch to ${
                        role === "admin" ? "Customer" : "Admin"
                      } view`}
                      className="px-0"
                      onClick={(e) => {
                        e.stopPropagation();
                      }}
                    >
                      {role === "admin" ? "Admin" : "Customer"}
                      <FontAwesomeIcon className="ml-2" icon={faChevronDown} />
                    </Button>
                  </Menu.Target>
                  <Menu.Dropdown>
                    <Menu.Item
                      onClick={() => {
                        setRole("admin");
                      }}
                    >
                      Admin
                    </Menu.Item>
                    <Menu.Item
                      onClick={() => {
                        setRole("customer");
                      }}
                    >
                      Customer
                    </Menu.Item>
                  </Menu.Dropdown>
                </Menu>
              )}
              <NotificationIcon />
              <UserMenu />
            </Group>
          ) : (
            <SignUpButton className="hidden lg:flex" />
          )}
        </Group>
      </Container>
    </header>
  );
}

function NotificationIcon() {
  const { data } = useNotificationCount();

  const showSpot = Boolean(data?.newNotificationCount);

  return (
    <Anchor
      component={Link}
      to={paths.notifications}
      aria-label="Notifications"
      className="relative size-6 text-center align-middle text-[--mantine-color-gray-6]"
    >
      <FontAwesomeIcon icon={faBell} className="size-4" />
      {showSpot && (
        <div className="absolute top-0 right-1 size-2 leading-none">
          <UnreadSpot />
        </div>
      )}
    </Anchor>
  );
}

function SignUpButton(props: { className?: string }) {
  const { openSignUpModal } = useSignUpSearchParams();

  return (
    <Group className={props.className}>
      <Button onClick={() => openSignUpModal()} variant="primary">
        Join GuideStack
      </Button>
      <Avatar />
    </Group>
  );
}

export { NavigationBar };
