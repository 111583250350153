import { Button, Flex, Modal, Stack, Text } from "@flpstudio/design-system";

import { Skeleton } from "@/components/atoms/Loading/Skeleton";
import { ComponentError } from "@/components/organisms/Error/ComponentError";
import { useBookingList } from "@/hooks/use-booking";
import { Amplitude, PaymentModalsEvent } from "@/third-party/amplitude";
import { formatMoney } from "@/utils/format-money";

type UnpaidBookingProps = {
  invoiceLink: string;
  onCancel: () => void;
};

const UnpaidBooking = (props: UnpaidBookingProps) => {
  const {
    data: bookingListData,
    isLoading,
    isError,
  } = useBookingList({
    enabled: true,
  });

  const unpaidBooking = bookingListData?.bookings?.find(
    (booking) => booking.status === "COMPLETED_UNPAID",
  );

  if (isError) {
    return <ComponentError />;
  }

  if (isLoading) {
    return <Skeleton />;
  }

  return (
    <Stack>
      {unpaidBooking && (
        <Stack className="rounded border border-[--mantine-color-gray-3] border-solid p-6">
          <Text className="font-semibold">
            Session with {unpaidBooking.expert?.name}
          </Text>
          <Text>
            Amount:{" "}
            {formatMoney({
              amount: unpaidBooking.price.amount,
              currency: unpaidBooking.price.currency,
            })}
          </Text>
        </Stack>
      )}
      <Flex className="flex-col lg:flex-row-reverse">
        <Button
          component="a"
          href={props.invoiceLink}
          target="_blank"
          rel="noopener noreferrer"
          onClick={() => {
            Amplitude.track(
              PaymentModalsEvent.name,
              PaymentModalsEvent.properties.unpaidModalPay,
            );
            props.onCancel();
          }}
        >
          Pay now
        </Button>
        <Button
          variant="outline"
          onClick={() => {
            Amplitude.track(
              PaymentModalsEvent.name,
              PaymentModalsEvent.properties.unpaidModalCancel,
            );
            props.onCancel();
          }}
        >
          Cancel
        </Button>
      </Flex>
    </Stack>
  );
};

type DialogProps = UnpaidBookingProps & {
  opened: boolean;
};

export const UnpaidBookingDialog = (props: DialogProps) => {
  return (
    <Modal
      title="Before booking another session, please pay for your recently completed session"
      opened={props.opened}
      onClose={props.onCancel}
      classNames={{
        title: "text-xl/normal font-semibold",
      }}
    >
      <UnpaidBooking {...props} />
    </Modal>
  );
};
