import cookie from "js-cookie";

export const SUBMISSION_ID_COOKIE_NAME = "GS_REC_SUBMISSION_ID";

export function saveSubmissionId(submissionId: string) {
  const hostName = window.location.hostname;

  // To make the cookie available on all subdomains, we need to set the domain to the root domain
  // Ex.: www.example.com -> example.com
  const domain = hostName.substring(hostName.indexOf(".") + 1);

  cookie.set(SUBMISSION_ID_COOKIE_NAME, submissionId, {
    expires: 1,
    sameSite: "lax",
    domain: import.meta.env.DEV ? undefined : domain,
    secure: import.meta.env.PROD,
  });
}
