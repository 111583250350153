import { useQuery } from "@tanstack/react-query";

import { httpClient } from "@/api/client";

const itRecommendationQueryKey = {
  itRecommendation: "itRecommendation",
};

type UseITRecommendationVariables = {
  submissionId: string;
  identifier: string;
};

export function useITRecommendation(variables: UseITRecommendationVariables) {
  return useQuery({
    queryKey: [
      itRecommendationQueryKey.itRecommendation,
      variables.submissionId,
      variables.identifier, // Only for caching purposes
    ],
    queryFn: async () => {
      const { data } = await httpClient.paths[
        "/v1/typeform/recommendation"
      ].get({
        submissionId: variables.submissionId,
      });
      return data;
    },
    enabled: Boolean(variables.submissionId || variables.identifier),
  });
}
