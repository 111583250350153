import { Paper, Text } from "@flpstudio/design-system";

import {
  Amplitude,
  ExpertDashboardCalloutsEvent,
} from "@/third-party/amplitude";
import { paths } from "~/paths";
import { StepItem } from "./CalloutStepItem";

type ExpertAccountSetupCalloutProps = {
  bookingOnboarded: boolean;
  payoutOnboarded: boolean;
};
export function ExpertAccountSetupCallout({
  bookingOnboarded,
  payoutOnboarded,
}: ExpertAccountSetupCalloutProps) {
  return (
    <Paper className="flex flex-col">
      <Text className="font-semibold text-xl">Finish expert account setup</Text>
      <p className="text-[--mantine-color-gray-7]">
        Congratulations, you’re a GuideStack expert! To accept consultations,
        please set up Calendly and Stripe accounts.
      </p>
      <Text className="font-semibold text-xl">Next steps</Text>
      <ul className="m-0 mt-4 flex list-none flex-col gap-4 p-0">
        <StepItem
          description="Set up Calendly for bookings"
          onClick={() => {
            Amplitude.track(
              ExpertDashboardCalloutsEvent.name,
              ExpertDashboardCalloutsEvent.properties.clickBookingSetup,
            );
          }}
          completed={bookingOnboarded}
          link={paths.account.root}
        />
        <StepItem
          description="Set up PayPal for payouts"
          onClick={() => {
            Amplitude.track(
              ExpertDashboardCalloutsEvent.name,
              ExpertDashboardCalloutsEvent.properties.clickPaymentSetup,
            );
          }}
          completed={payoutOnboarded}
          link={paths.account.payouts}
        />
      </ul>
    </Paper>
  );
}
