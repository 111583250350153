import { useMutation } from "@tanstack/react-query";

import { httpClient } from "@/api/client";

type SubmitITRecommendationVariables = {
  submissionId: string;
};

export function useITRecommendationTypeFormQuizMutation() {
  return useMutation({
    mutationFn: async (variables: SubmitITRecommendationVariables) =>
      httpClient.paths["/v1/typeform/quiz"].post(null, variables),
  });
}
