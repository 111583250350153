import { Paper } from "@flpstudio/design-system";
import { clsx } from "clsx/lite";

import { CreateDocumentForm } from "@/components/organisms/CreateDocumentForm/CreateDocumentForm";
import { useExpertReadiness } from "@/hooks/use-expert";

import { ExpertAccountSetupCallout } from "./ExpertAccountSetupCallout";
import { ExpertNextStepCallout } from "./ExpertNextStepCallout";

type Props = {
  className?: string;
};

export function ExpertCalloutContainer(props: Props) {
  const {
    hasSoftware,
    hasIntroVideo,
    hasTechnicalArea,
    hasIndustry,
    hasDocument,
    isProfileSetupDone,
    isApproved,
    isPayoutOnboarded,
    isBookingOnboarded,
  } = useExpertReadiness();
  const completedProfile =
    hasDocument &&
    hasIntroVideo &&
    hasTechnicalArea &&
    hasIndustry &&
    hasSoftware &&
    isProfileSetupDone;

  const completedAccountSetup = isPayoutOnboarded && isBookingOnboarded;

  const showAccountSetupCallout =
    completedProfile && isApproved && !completedAccountSetup;

  return completedProfile ? (
    <div className={clsx("flex flex-col gap-2", props.className)}>
      {showAccountSetupCallout && (
        <ExpertAccountSetupCallout
          bookingOnboarded={isBookingOnboarded}
          payoutOnboarded={isPayoutOnboarded}
        />
      )}
      <Paper>
        <CreateDocumentForm layout="vertical" />
      </Paper>
    </div>
  ) : (
    <div className={props.className}>
      <ExpertNextStepCallout
        hasDocument={hasDocument}
        hasIntroVideo={hasIntroVideo}
        hasTechnicalArea={hasTechnicalArea}
        hasIndustry={hasIndustry}
        hasSoftware={hasSoftware}
        isProfileSetupDone={isProfileSetupDone}
      />
    </div>
  );
}
