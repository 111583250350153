import { Paper, Text } from "@flpstudio/design-system";

import { useProfilePageSearchParams } from "@/hooks/use-page-search-params";
import {
  Amplitude,
  ExpertDashboardCalloutsEvent,
} from "@/third-party/amplitude";
import { paths } from "~/paths";
import { StepItem } from "./CalloutStepItem";

type NextStepCalloutProps = {
  hasDocument: boolean;
  hasIntroVideo: boolean;
  hasTechnicalArea: boolean;
  hasIndustry: boolean;
  hasSoftware: boolean;
  isProfileSetupDone: boolean;
};

export function ExpertNextStepCallout({
  hasDocument,
  hasIntroVideo,
  hasTechnicalArea,
  hasIndustry,
  hasSoftware,
  isProfileSetupDone,
}: NextStepCalloutProps) {
  const { generateModalOpenLink } = useProfilePageSearchParams();

  return (
    <Paper className="flex flex-col">
      <Text className="font-semibold text-xl">Become a GuideStack expert</Text>
      <p className="text-[--mantine-color-gray-7]">
        To accept GuideStack client bookings, provide at least one guide for our
        expert guides repository. Once approved, clients can directly book paid
        consultations with you.
      </p>
      <Text className="font-semibold text-xl">Next steps</Text>
      <ul className="m-0 mt-4 flex list-none flex-col gap-4 p-0">
        <StepItem
          description="Complete profile setup"
          onClick={() => {
            Amplitude.track(
              ExpertDashboardCalloutsEvent.name,
              ExpertDashboardCalloutsEvent.properties.completeProfile,
            );
          }}
          completed={isProfileSetupDone}
          link={generateModalOpenLink("myProfile")}
        />
        <StepItem
          description="Add your video introduction"
          onClick={() => {
            Amplitude.track(
              ExpertDashboardCalloutsEvent.name,
              ExpertDashboardCalloutsEvent.properties.addIntroVideo,
            );
          }}
          completed={hasIntroVideo}
          link={generateModalOpenLink("introVideo")}
        />
        <StepItem
          description="Add your software expertise"
          onClick={() => {
            Amplitude.track(
              ExpertDashboardCalloutsEvent.name,
              ExpertDashboardCalloutsEvent.properties.addSoftware,
            );
          }}
          completed={hasSoftware}
          link={generateModalOpenLink("software")}
        />
        <StepItem
          description="Add your technical expertise"
          onClick={() => {
            Amplitude.track(
              ExpertDashboardCalloutsEvent.name,
              ExpertDashboardCalloutsEvent.properties.addTechnicalExpertise,
            );
          }}
          completed={hasTechnicalArea}
          link={generateModalOpenLink("technicalAreas")}
        />
        <StepItem
          description="Add your industry expertise"
          onClick={() => {
            Amplitude.track(
              ExpertDashboardCalloutsEvent.name,
              ExpertDashboardCalloutsEvent.properties.addIndustries,
            );
          }}
          completed={hasIndustry}
          link={generateModalOpenLink("industry")}
        />
        <StepItem
          description="Write a guide"
          onClick={() => {
            Amplitude.track(
              ExpertDashboardCalloutsEvent.name,
              ExpertDashboardCalloutsEvent.properties.writeDocument,
            );
          }}
          completed={hasDocument}
          link={paths.profile.documents.root}
        />
      </ul>
    </Paper>
  );
}
