import { isBefore, subYears } from "date-fns";

/**
 * Formats a date into a human-readable string
 * This is a very simple implementation,
 * and we may swap it out for a library like date-fns in the future for more
 * robust date formatting capabilities and localization support.
 */
const formatDate = (date: Date) => {
  // otherwise, return "on [month] [day], [year]"

  const diffInSeconds = (new Date().getTime() - date.getTime()) / 1000;

  // if date is less than 2 minutes ago, return "1 minute ago"
  if (diffInSeconds < 120) return "1 minute ago";

  // if date is less than 1 hour ago, return "x minutes ago"
  if (diffInSeconds < 3600)
    return `${Math.floor(diffInSeconds / 60)} minutes ago`;

  // if date is more than 1 hour ago but less than 2 hours ago, return "1 hour ago"
  if (diffInSeconds < 7200) return "1 hour ago";

  // if date is more than 2 hours ago but less than 24 hours ago, return "x hours ago"
  if (diffInSeconds < 86400)
    return `${Math.floor(diffInSeconds / 3600)} hours ago`;

  // if date is more than 24 hours but less than 1 year, and it is in the same year as now, return "on [month] [day]"
  const oneYearAgo = subYears(new Date(), 1);
  if (!isBefore(date, oneYearAgo)) {
    return `on ${date.toLocaleDateString("en-US", {
      month: "long",
      day: "numeric",
    })}`;
  }

  // otherwise, return "on [month] [day], [year]"
  return `on ${date.toLocaleDateString("en-US", {
    month: "long",
    day: "numeric",
    year: "numeric",
  })}`;
};

export { formatDate };
