import { Paper, Text } from "@flpstudio/design-system";
import { clsx } from "clsx/lite";

import { isProfileCompleted } from "@/components/organisms/ClientOnboarding/ClientProfileForm";
import { isCompanyInfoCompleted } from "@/components/organisms/ClientOnboarding/CompanyInfoForm";
import { isITSetupCompleted } from "@/components/organisms/ClientOnboarding/ITSetupForm";
import { useAuth } from "@/hooks/use-auth";
import { useProfilePageSearchParams } from "@/hooks/use-page-search-params";
import { useClientSoftware } from "@/hooks/use-software";
import {
  Amplitude,
  ClientDashboardCalloutsEvent,
} from "@/third-party/amplitude";
import { StepItem } from "./CalloutStepItem";

type Props = {
  className?: string;
};

export function ClientNextStepCallout(props: Props) {
  const { user } = useAuth();
  const { generateModalOpenLink } = useProfilePageSearchParams();
  const { data: softwares } = useClientSoftware();

  if (!user) {
    return null;
  }

  const hasSoftware = Boolean(softwares?.length);
  const isProfileSetupDone = isProfileCompleted(user);
  const isCompanyInfoDone = isCompanyInfoCompleted(user);
  const isITSetupDone = isITSetupCompleted(user);

  const completedAllSteps =
    hasSoftware && isProfileSetupDone && isCompanyInfoDone && isITSetupDone;

  if (completedAllSteps) {
    return null;
  }

  return (
    <Paper className={clsx("flex h-fit flex-col", props.className)}>
      <Text className="font-semibold text-xl">Next steps</Text>
      <ul className="m-0 mt-4 flex list-none flex-col gap-4 p-0">
        <StepItem
          description="Complete profile setup"
          completed={isProfileSetupDone}
          link={generateModalOpenLink("myProfile")}
          onClick={() => {
            Amplitude.track(
              ClientDashboardCalloutsEvent.name,
              ClientDashboardCalloutsEvent.properties.clickCompleteProfile,
            );
          }}
        />
        <StepItem
          description="Provide info about your software usage"
          completed={hasSoftware}
          link={generateModalOpenLink("software")}
          onClick={() => {
            Amplitude.track(
              ClientDashboardCalloutsEvent.name,
              ClientDashboardCalloutsEvent.properties.clickAddSoftware,
            );
          }}
        />
        <StepItem
          description="Complete company info"
          completed={isCompanyInfoDone}
          link={generateModalOpenLink("companyInfo")}
        />
        <StepItem
          description="Complete company IT setup"
          completed={isITSetupDone}
          link={generateModalOpenLink("itSetup")}
        />
      </ul>
    </Paper>
  );
}
