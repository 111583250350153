/* tslint:disable */
/* eslint-disable */
// @ts-nocheck
/**
 * Ampli - A strong typed wrapper for your Analytics
 *
 * This file is generated by Amplitude.
 * To update run 'ampli pull web'
 *
 * Required dependencies: @amplitude/analytics-browser@^1.3.0
 * Tracking Plan Version: 1
 * Build: 1.0.0
 * Runtime: browser:typescript-ampli-v2
 *
 * [View Tracking Plan](https://data.amplitude.com/fujitsu-launchpad/GuideStack%20Production/events/main/latest)
 *
 * [Full Setup Instructions](https://data.amplitude.com/fujitsu-launchpad/GuideStack%20Production/implementation/web)
 */

import * as amplitude from '@amplitude/analytics-browser';

export type Environment = 'guidestackproduction';

export const ApiKey: Record<Environment, string> = {
  guidestackproduction: ''
};

/**
 * Default Amplitude configuration options. Contains tracking plan information.
 */
export const DefaultConfiguration: BrowserOptions = {
  plan: {
    version: '1',
    branch: 'main',
    source: 'web',
    versionId: 'bd148f8e-8aad-4f53-872c-f1a311f09cc8'
  },
  ...{
    ingestionMetadata: {
      sourceName: 'browser-typescript-ampli',
      sourceVersion: '2.0.0'
    }
  }
};

export interface LoadOptionsBase { disabled?: boolean }

export type LoadOptionsWithEnvironment = LoadOptionsBase & { environment: Environment; client?: { configuration?: BrowserOptions; }; };
export type LoadOptionsWithApiKey = LoadOptionsBase & { client: { apiKey: string; configuration?: BrowserOptions; } };
export type LoadOptionsWithClientInstance = LoadOptionsBase & { client: { instance: BrowserClient; } };

export type LoadOptions = LoadOptionsWithEnvironment | LoadOptionsWithApiKey | LoadOptionsWithClientInstance;

export interface IdentifyProperties {
  approved_expert?: string;
  client_company_size?: string;
  client_industry?: string;
  client_IT_level?: string;
  client_job_title?: string;
  client_productivity_suite?: string;
  client_software_used?: string;
  client_work_setup?: string;
  country?: string;
  dashboard_tab_test?: string;
  expert_company_size_preference?: string;
  expert_industry_expertise?: string;
  expert_IT_maturity_preference?: string;
  expert_software_expertise?: string;
  fbclid?: string;
  initial_dclid?: string;
  initial_fbclid?: string;
  initial_gbraid?: string;
  initial_gclid?: string;
  initial_ko_click_id?: string;
  initial_li_fat_id?: string;
  initial_msclkid?: string;
  initial_referrer?: string;
  initial_referring_domain?: string;
  initial_rtd_cid?: string;
  initial_ttclid?: string;
  initial_twclid?: string;
  initial_utm_campaign?: string;
  initial_utm_content?: string;
  initial_utm_id?: string;
  initial_utm_medium?: string;
  initial_utm_source?: string;
  initial_utm_term?: string;
  initial_wbraid?: string;
  published_document_count?: string;
  referrer?: string;
  referring_domain?: string;
  user_type?: string;
  utm_campaign?: string;
  utm_content?: string;
  utm_id?: string;
  utm_medium?: string;
  utm_source?: string;
  utm_term?: string;
}

export interface ClickSearchResultProperties {
  /**
   * Category of the search result interacted with by the user.
   *
   * | Rule | Value |
   * |---|---|
   * | Enum Values | Guide, Consulting Log, Expert, Comment |
   */
  search_result_category: "Guide" | "Consulting Log" | "Expert" | "Comment";
  /**
   * URL of the search result interacted with by the user.
   */
  search_result_url: string;
  /**
   * Exact keyword searched for by the user.
   */
  search_term: string;
}

export interface EnterSearchTermProperties {
  /**
   * Exact keyword searched for by the user.
   */
  search_term: string;
}

export interface NextStepsClickBookFreeIntroProperties {
  /**
   * Indicates the page where the user interacted with a callout.
   *
   * | Rule | Value |
   * |---|---|
   * | Enum Values | Experts, Guides, Sessions, Client Profile |
   */
  callout_location: "Experts" | "Guides" | "Sessions" | "Client Profile";
  /**
   * Name of the expert whose profile or guide is being interacted with.
   */
  expert_name: string;
  /**
   * Indicates whether the user is logged in or logged out.
   *
   * | Rule | Value |
   * |---|---|
   * | Enum Values | Logged In, Logged Out |
   */
  user_status?: "Logged In" | "Logged Out";
}

export interface NextStepsClickBookSessionProperties {
  /**
   * Indicates the page where the user interacted with a callout.
   *
   * | Rule | Value |
   * |---|---|
   * | Enum Values | Experts, Guides, Sessions, Client Profile |
   */
  callout_location: "Experts" | "Guides" | "Sessions" | "Client Profile";
  /**
   * Name of the expert whose profile or guide is being interacted with.
   */
  expert_name: string;
  /**
   * Indicates whether the user is logged in or logged out.
   *
   * | Rule | Value |
   * |---|---|
   * | Enum Values | Logged In, Logged Out |
   */
  user_status?: "Logged In" | "Logged Out";
}

export interface NextStepsClickGuideProperties {
  /**
   * Indicates the page where the user interacted with a callout.
   *
   * | Rule | Value |
   * |---|---|
   * | Enum Values | Experts, Guides, Sessions, Client Profile |
   */
  callout_location: "Experts" | "Guides" | "Sessions" | "Client Profile";
  /**
   * URL of the link interacted with by the user.
   *
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  url?: string;
  /**
   * Indicates whether the user is logged in or logged out.
   *
   * | Rule | Value |
   * |---|---|
   * | Enum Values | Logged In, Logged Out |
   */
  user_status?: "Logged In" | "Logged Out";
}

export interface NextStepsClickViewFullProperties {
  /**
   * Indicates the page where the user interacted with a callout.
   *
   * | Rule | Value |
   * |---|---|
   * | Enum Values | Experts, Guides, Sessions, Client Profile |
   */
  callout_location: "Experts" | "Guides" | "Sessions" | "Client Profile";
  /**
   * Indicates whether the user is logged in or logged out.
   *
   * | Rule | Value |
   * |---|---|
   * | Enum Values | Logged In, Logged Out |
   */
  user_status?: "Logged In" | "Logged Out";
}

export interface QuizCalloutClickTakeQuizProperties {
  /**
   * Indicates the page where the user interacted with a callout.
   *
   * | Rule | Value |
   * |---|---|
   * | Enum Values | Experts, Guides, Sessions, Client Profile |
   */
  callout_location: "Experts" | "Guides" | "Sessions" | "Client Profile";
  /**
   * Indicates whether the user is logged in or logged out.
   *
   * | Rule | Value |
   * |---|---|
   * | Enum Values | Logged In, Logged Out |
   */
  user_status?: "Logged In" | "Logged Out";
}

export interface RecommendationsClickBookFreeIntroProperties {
  /**
   * Name of the expert whose profile or guide is being interacted with.
   */
  expert_name: string;
  /**
   * Indicates whether the user is logged in or logged out.
   *
   * | Rule | Value |
   * |---|---|
   * | Enum Values | Logged In, Logged Out |
   */
  user_status?: "Logged In" | "Logged Out";
}

export interface RecommendationsClickBookSessionProperties {
  /**
   * Name of the expert whose profile or guide is being interacted with.
   */
  expert_name: string;
  /**
   * Indicates whether the user is logged in or logged out.
   *
   * | Rule | Value |
   * |---|---|
   * | Enum Values | Logged In, Logged Out |
   */
  user_status?: "Logged In" | "Logged Out";
}

export interface RecommendationsClickGuideProperties {
  /**
   * URL of the link interacted with by the user.
   *
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  url?: string;
  /**
   * Indicates whether the user is logged in or logged out.
   *
   * | Rule | Value |
   * |---|---|
   * | Enum Values | Logged In, Logged Out |
   */
  user_status?: "Logged In" | "Logged Out";
}

export interface RecommendationsClickSkipProperties {
  /**
   * Indicates whether the user is logged in or logged out.
   *
   * | Rule | Value |
   * |---|---|
   * | Enum Values | Logged In, Logged Out |
   */
  user_status?: "Logged In" | "Logged Out";
}

export interface RecommendationsClickViewProfileProperties {
  /**
   * Name of the expert whose profile or guide is being interacted with.
   */
  expert_name: string;
  /**
   * Indicates whether the user is logged in or logged out.
   *
   * | Rule | Value |
   * |---|---|
   * | Enum Values | Logged In, Logged Out |
   */
  user_status?: "Logged In" | "Logged Out";
}

export interface SearchResultsClickCategoryProperties {
  /**
   * Category of the search result interacted with by the user.
   *
   * | Rule | Value |
   * |---|---|
   * | Enum Values | Guide, Consulting Log, Expert, Comment |
   */
  search_result_category: "Guide" | "Consulting Log" | "Expert" | "Comment";
  /**
   * Exact keyword searched for by the user.
   */
  search_term: string;
}

export class Identify implements BaseEvent {
  event_type = amplitude.Types.SpecialEventType.IDENTIFY;

  constructor(
    public event_properties?: IdentifyProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class AccountClickDeleteAccount implements BaseEvent {
  event_type = 'Account- Click Delete Account';
}

export class AccountConfirmAccountDeletion implements BaseEvent {
  event_type = 'Account- Confirm Account Deletion';
}

export class ClickClearAllSearches implements BaseEvent {
  event_type = 'Click Clear All Searches';
}

export class ClickSearchResult implements BaseEvent {
  event_type = 'Click Search Result';

  constructor(
    public event_properties: ClickSearchResultProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class ClientProfileClickPastQuiz implements BaseEvent {
  event_type = 'Client Profile- Click Past Quiz';
}

export class ClientProfileClickTakeQuiz implements BaseEvent {
  event_type = 'Client Profile- Click Take Quiz';
}

export class EnterSearchTerm implements BaseEvent {
  event_type = 'Enter Search Term';

  constructor(
    public event_properties: EnterSearchTermProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class NextStepsClickBookFreeIntro implements BaseEvent {
  event_type = 'Next Steps- Click Book Free Intro';

  constructor(
    public event_properties: NextStepsClickBookFreeIntroProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class NextStepsClickBookSession implements BaseEvent {
  event_type = 'Next Steps- Click Book Session';

  constructor(
    public event_properties: NextStepsClickBookSessionProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class NextStepsClickGuide implements BaseEvent {
  event_type = 'Next Steps- Click Guide';

  constructor(
    public event_properties: NextStepsClickGuideProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class NextStepsClickViewFull implements BaseEvent {
  event_type = 'Next Steps- Click View Full';

  constructor(
    public event_properties: NextStepsClickViewFullProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class QuizCalloutClickTakeQuiz implements BaseEvent {
  event_type = 'Quiz Callout- Click Take Quiz';

  constructor(
    public event_properties: QuizCalloutClickTakeQuizProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class RecommendationsClickBookFreeIntro implements BaseEvent {
  event_type = 'Recommendations- Click Book Free Intro';

  constructor(
    public event_properties: RecommendationsClickBookFreeIntroProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class RecommendationsClickBookSession implements BaseEvent {
  event_type = 'Recommendations- Click Book Session';

  constructor(
    public event_properties: RecommendationsClickBookSessionProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class RecommendationsClickGuide implements BaseEvent {
  event_type = 'Recommendations- Click Guide';

  constructor(
    public event_properties?: RecommendationsClickGuideProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class RecommendationsClickSkip implements BaseEvent {
  event_type = 'Recommendations- Click Skip';

  constructor(
    public event_properties?: RecommendationsClickSkipProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class RecommendationsClickViewProfile implements BaseEvent {
  event_type = 'Recommendations- Click View Profile';

  constructor(
    public event_properties: RecommendationsClickViewProfileProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class SearchResultsClickCategory implements BaseEvent {
  event_type = 'Search Results- Click Category';

  constructor(
    public event_properties: SearchResultsClickCategoryProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export type PromiseResult<T> = { promise: Promise<T | void> };

const getVoidPromiseResult = () => ({ promise: Promise.resolve() });

// prettier-ignore
export class Ampli {
  private disabled: boolean = false;
  private amplitude?: BrowserClient;

  get client(): BrowserClient {
    this.isInitializedAndEnabled();
    return this.amplitude!;
  }

  get isLoaded(): boolean {
    return this.amplitude != null;
  }

  private isInitializedAndEnabled(): boolean {
    if (!this.amplitude) {
      console.error('ERROR: Ampli is not yet initialized. Have you called ampli.load() on app start?');
      return false;
    }
    return !this.disabled;
  }

  /**
   * Initialize the Ampli SDK. Call once when your application starts.
   *
   * @param options Configuration options to initialize the Ampli SDK with.
   */
  load(options: LoadOptions): PromiseResult<void> {
    this.disabled = options.disabled ?? false;

    if (this.amplitude) {
      console.warn('WARNING: Ampli is already intialized. Ampli.load() should be called once at application startup.');
      return getVoidPromiseResult();
    }

    let apiKey: string | null = null;
    if (options.client && 'apiKey' in options.client) {
      apiKey = options.client.apiKey;
    } else if ('environment' in options) {
      apiKey = ApiKey[options.environment];
    }

    if (options.client && 'instance' in options.client) {
      this.amplitude = options.client.instance;
    } else if (apiKey) {
      this.amplitude = amplitude.createInstance();
      const configuration = (options.client && 'configuration' in options.client) ? options.client.configuration : {};
      return this.amplitude.init(apiKey, undefined, { ...DefaultConfiguration, ...configuration });
    } else {
      console.error("ERROR: ampli.load() requires 'environment', 'client.apiKey', or 'client.instance'");
    }

    return getVoidPromiseResult();
  }

  /**
   * Identify a user and set user properties.
   *
   * @param userId The user's id.
   * @param properties The user properties.
   * @param options Optional event options.
   */
  identify(
    userId: string | undefined,
    properties?: IdentifyProperties,
    options?: EventOptions,
  ): PromiseResult<Result> {
    if (!this.isInitializedAndEnabled()) {
      return getVoidPromiseResult();
    }

    if (userId) {
      options = {...options,  user_id: userId};
    }

    const amplitudeIdentify = new amplitude.Identify();
    const eventProperties = properties;
    if (eventProperties != null) {
      for (const [key, value] of Object.entries(eventProperties)) {
        amplitudeIdentify.set(key, value);
      }
    }
    return this.amplitude!.identify(
      amplitudeIdentify,
      options,
    );
  }

 /**
  * Flush the event.
  */
  flush() : PromiseResult<Result> {
    if (!this.isInitializedAndEnabled()) {
      return getVoidPromiseResult();
    }

    return this.amplitude!.flush();
  }

  /**
   * Track event
   *
   * @param event The event to track.
   * @param options Optional event options.
   */
  track(event: Event, options?: EventOptions): PromiseResult<Result> {
    if (!this.isInitializedAndEnabled()) {
      return getVoidPromiseResult();
    }

    return this.amplitude!.track(event, undefined, options);
  }

  /**
   * Account- Click Delete Account
   *
   * [View in Tracking Plan](https://data.amplitude.com/fujitsu-launchpad/GuideStack%20Production/events/main/latest/Account-%20Click%20Delete%20Account)
   *
   * Event triggered when the user clicks 'Delete my account' on their Account tab.
   *
   * @param options Amplitude event options.
   */
  accountClickDeleteAccount(
    options?: EventOptions,
  ) {
    return this.track(new AccountClickDeleteAccount(), options);
  }

  /**
   * Account- Confirm Account Deletion
   *
   * [View in Tracking Plan](https://data.amplitude.com/fujitsu-launchpad/GuideStack%20Production/events/main/latest/Account-%20Confirm%20Account%20Deletion)
   *
   * Event triggered when the user confirms account deletion in the dialog.
   *
   * @param options Amplitude event options.
   */
  accountConfirmAccountDeletion(
    options?: EventOptions,
  ) {
    return this.track(new AccountConfirmAccountDeletion(), options);
  }

  /**
   * Click Clear All Searches
   *
   * [View in Tracking Plan](https://data.amplitude.com/fujitsu-launchpad/GuideStack%20Production/events/main/latest/Click%20Clear%20All%20Searches)
   *
   * Event triggered when the user clicks 'Clear all' for their recent searches.
   *
   * @param options Amplitude event options.
   */
  clickClearAllSearches(
    options?: EventOptions,
  ) {
    return this.track(new ClickClearAllSearches(), options);
  }

  /**
   * Click Search Result
   *
   * [View in Tracking Plan](https://data.amplitude.com/fujitsu-launchpad/GuideStack%20Production/events/main/latest/Click%20Search%20Result)
   *
   * Event triggered when the user clicks any search result from the search results page.
   *
   * @param properties The event's properties (e.g. search_result_category)
   * @param options Amplitude event options.
   */
  clickSearchResult(
    properties: ClickSearchResultProperties,
    options?: EventOptions,
  ) {
    return this.track(new ClickSearchResult(properties), options);
  }

  /**
   * Client Profile- Click Past Quiz
   *
   * [View in Tracking Plan](https://data.amplitude.com/fujitsu-launchpad/GuideStack%20Production/events/main/latest/Client%20Profile-%20Click%20Past%20Quiz)
   *
   * Event triggered when the user clicks a past assessment text button on their own profile.
   *
   * @param options Amplitude event options.
   */
  clientProfileClickPastQuiz(
    options?: EventOptions,
  ) {
    return this.track(new ClientProfileClickPastQuiz(), options);
  }

  /**
   * Client Profile- Click Take Quiz
   *
   * [View in Tracking Plan](https://data.amplitude.com/fujitsu-launchpad/GuideStack%20Production/events/main/latest/Client%20Profile-%20Click%20Take%20Quiz)
   *
   * Event triggered when the user clicks 'Take the quiz' on their own profile.
   *
   * @param options Amplitude event options.
   */
  clientProfileClickTakeQuiz(
    options?: EventOptions,
  ) {
    return this.track(new ClientProfileClickTakeQuiz(), options);
  }

  /**
   * Enter Search Term
   *
   * [View in Tracking Plan](https://data.amplitude.com/fujitsu-launchpad/GuideStack%20Production/events/main/latest/Enter%20Search%20Term)
   *
   * Event triggered when the user clicks enter to conduct a search, after entering a term.
   *
   * @param properties The event's properties (e.g. search_term)
   * @param options Amplitude event options.
   */
  enterSearchTerm(
    properties: EnterSearchTermProperties,
    options?: EventOptions,
  ) {
    return this.track(new EnterSearchTerm(properties), options);
  }

  /**
   * Next Steps- Click Book Free Intro
   *
   * [View in Tracking Plan](https://data.amplitude.com/fujitsu-launchpad/GuideStack%20Production/events/main/latest/Next%20Steps-%20Click%20Book%20Free%20Intro)
   *
   * Event triggered when the user clicks 'Book a free intro call' on the IT next steps callout.
   *
   * @param properties The event's properties (e.g. callout_location)
   * @param options Amplitude event options.
   */
  nextStepsClickBookFreeIntro(
    properties: NextStepsClickBookFreeIntroProperties,
    options?: EventOptions,
  ) {
    return this.track(new NextStepsClickBookFreeIntro(properties), options);
  }

  /**
   * Next Steps- Click Book Session
   *
   * [View in Tracking Plan](https://data.amplitude.com/fujitsu-launchpad/GuideStack%20Production/events/main/latest/Next%20Steps-%20Click%20Book%20Session)
   *
   * Event triggered when the user clicks 'Book a session' on the IT next steps callout.
   *
   * @param properties The event's properties (e.g. callout_location)
   * @param options Amplitude event options.
   */
  nextStepsClickBookSession(
    properties: NextStepsClickBookSessionProperties,
    options?: EventOptions,
  ) {
    return this.track(new NextStepsClickBookSession(properties), options);
  }

  /**
   * Next Steps- Click Guide
   *
   * [View in Tracking Plan](https://data.amplitude.com/fujitsu-launchpad/GuideStack%20Production/events/main/latest/Next%20Steps-%20Click%20Guide)
   *
   * Event triggered when the user clicks a guide on their IT next steps callout.
   *
   * @param properties The event's properties (e.g. callout_location)
   * @param options Amplitude event options.
   */
  nextStepsClickGuide(
    properties: NextStepsClickGuideProperties,
    options?: EventOptions,
  ) {
    return this.track(new NextStepsClickGuide(properties), options);
  }

  /**
   * Next Steps- Click View Full
   *
   * [View in Tracking Plan](https://data.amplitude.com/fujitsu-launchpad/GuideStack%20Production/events/main/latest/Next%20Steps-%20Click%20View%20Full)
   *
   * Event triggered when the user clicks 'View full recommendation' on their IT next steps callout.
   *
   * @param properties The event's properties (e.g. callout_location)
   * @param options Amplitude event options.
   */
  nextStepsClickViewFull(
    properties: NextStepsClickViewFullProperties,
    options?: EventOptions,
  ) {
    return this.track(new NextStepsClickViewFull(properties), options);
  }

  /**
   * Quiz Callout- Click Take Quiz
   *
   * [View in Tracking Plan](https://data.amplitude.com/fujitsu-launchpad/GuideStack%20Production/events/main/latest/Quiz%20Callout-%20Click%20Take%20Quiz)
   *
   * Event triggered when the user clicks the CTA on the quiz callout on any page.
   *
   * @param properties The event's properties (e.g. callout_location)
   * @param options Amplitude event options.
   */
  quizCalloutClickTakeQuiz(
    properties: QuizCalloutClickTakeQuizProperties,
    options?: EventOptions,
  ) {
    return this.track(new QuizCalloutClickTakeQuiz(properties), options);
  }

  /**
   * Recommendations- Click Book Free Intro
   *
   * [View in Tracking Plan](https://data.amplitude.com/fujitsu-launchpad/GuideStack%20Production/events/main/latest/Recommendations-%20Click%20Book%20Free%20Intro)
   *
   * Event triggered when the user clicks 'Book a free intro call' on their IT recommendations page.
   *
   * @param properties The event's properties (e.g. expert_name)
   * @param options Amplitude event options.
   */
  recommendationsClickBookFreeIntro(
    properties: RecommendationsClickBookFreeIntroProperties,
    options?: EventOptions,
  ) {
    return this.track(new RecommendationsClickBookFreeIntro(properties), options);
  }

  /**
   * Recommendations- Click Book Session
   *
   * [View in Tracking Plan](https://data.amplitude.com/fujitsu-launchpad/GuideStack%20Production/events/main/latest/Recommendations-%20Click%20Book%20Session)
   *
   * Event triggered when the user clicks 'Book a session' on their IT recommendations page.
   *
   * @param properties The event's properties (e.g. expert_name)
   * @param options Amplitude event options.
   */
  recommendationsClickBookSession(
    properties: RecommendationsClickBookSessionProperties,
    options?: EventOptions,
  ) {
    return this.track(new RecommendationsClickBookSession(properties), options);
  }

  /**
   * Recommendations- Click Guide
   *
   * [View in Tracking Plan](https://data.amplitude.com/fujitsu-launchpad/GuideStack%20Production/events/main/latest/Recommendations-%20Click%20Guide)
   *
   * Event triggered when the user clicks any suggested guide on their IT recommendations page.
   *
   * @param properties The event's properties (e.g. url)
   * @param options Amplitude event options.
   */
  recommendationsClickGuide(
    properties?: RecommendationsClickGuideProperties,
    options?: EventOptions,
  ) {
    return this.track(new RecommendationsClickGuide(properties), options);
  }

  /**
   * Recommendations- Click Skip
   *
   * [View in Tracking Plan](https://data.amplitude.com/fujitsu-launchpad/GuideStack%20Production/events/main/latest/Recommendations-%20Click%20Skip)
   *
   * Event triggered when the user clicks the skip button on their IT recommendations page.
   *
   * @param properties The event's properties (e.g. user_status)
   * @param options Amplitude event options.
   */
  recommendationsClickSkip(
    properties?: RecommendationsClickSkipProperties,
    options?: EventOptions,
  ) {
    return this.track(new RecommendationsClickSkip(properties), options);
  }

  /**
   * Recommendations- Click View Profile
   *
   * [View in Tracking Plan](https://data.amplitude.com/fujitsu-launchpad/GuideStack%20Production/events/main/latest/Recommendations-%20Click%20View%20Profile)
   *
   * Event triggered when the user clicks 'View profile' for the expert on their IT recommendations page.
   *
   * @param properties The event's properties (e.g. expert_name)
   * @param options Amplitude event options.
   */
  recommendationsClickViewProfile(
    properties: RecommendationsClickViewProfileProperties,
    options?: EventOptions,
  ) {
    return this.track(new RecommendationsClickViewProfile(properties), options);
  }

  /**
   * Search Results- Click Category
   *
   * [View in Tracking Plan](https://data.amplitude.com/fujitsu-launchpad/GuideStack%20Production/events/main/latest/Search%20Results-%20Click%20Category)
   *
   * Event triggered when the user clicks any category at the top of the search results.
   *
   * @param properties The event's properties (e.g. search_result_category)
   * @param options Amplitude event options.
   */
  searchResultsClickCategory(
    properties: SearchResultsClickCategoryProperties,
    options?: EventOptions,
  ) {
    return this.track(new SearchResultsClickCategory(properties), options);
  }
}

export const ampli = new Ampli();

// BASE TYPES
type BrowserOptions = amplitude.Types.BrowserOptions;

export type BrowserClient = amplitude.Types.BrowserClient;
export type BaseEvent = amplitude.Types.BaseEvent;
export type IdentifyEvent = amplitude.Types.IdentifyEvent;
export type GroupEvent = amplitude.Types.GroupIdentifyEvent;
export type Event = amplitude.Types.Event;
export type EventOptions = amplitude.Types.EventOptions;
export type Result = amplitude.Types.Result;
